import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useContext, useEffect, useMemo, useRef } from 'react'

import { ENV } from '@/config/environment'
import HNContext from '@/context/HNContext'

export default function HNSegment() {
  const { userProfile, organization, organizationPlan, isAdmin } =
    useContext(HNContext)
  const dataSent = useRef(false)
  const router = useRouter()
  const { pathname } = router

  const orgDetails = useMemo(() => {
    if (!organization) return {}
    const finalData = {}
    Object.keys(organization).map((key: string) => {
      // @ts-ignore
      finalData[`organization-${key}`] = organization[key]
      return true
    })
    Object.keys(organizationPlan || {}).map((key: string) => {
      // @ts-ignore
      finalData[`organizationPlan-${key}`] = organizationPlan[key]
      return true
    })

    return finalData
  }, [organization, organizationPlan])

  const identity = useMemo(() => {
    return {
      ...(userProfile || {}),
      ...(orgDetails || {}),
      name: userProfile?.name,
      email: userProfile?.email,
      isHuracan: true,
    }
  }, [userProfile, orgDetails])

  const initPostHog = async () => {
    if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) return

    if (
      !userProfile?.id ||
      (!userProfile?.is_csm_of_organization && pathname !== '/new')
    )
      return

    if (!organization && pathname !== '/new') return

    const posthog = (await import('posthog-js')).default

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host: 'https://ph.featureos.app',
    })
    posthog.identify(userProfile.id, identity)
    if (organization) {
      posthog.group('Organization', organization.domain, organization)
    }
    dataSent.current = true
  }

  useEffect(() => {
    try {
      // if (process.env.NODE_ENV === 'development') return
      if (dataSent.current) return
      initPostHog()
    } catch (err) {
      console.error(err)
    }
  }, [orgDetails, userProfile, identity, pathname])

  if (!ENV.ADMIN_SIDE_ANALYTICS_ID || !ENV.USER_SIDE_ANALYTICS_ID) return <></>

  return (
    <Script
      defer
      src={ENV.ANALYTICS_URL}
      data-website-id={
        isAdmin ? ENV.ADMIN_SIDE_ANALYTICS_ID : ENV.USER_SIDE_ANALYTICS_ID
      }
    ></Script>
  )
}
